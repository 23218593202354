<template>
	<v-container fluid class="mb-12 pb-0">
		<v-row>
			<v-col class="mt-3 mr-5 d-flex justify-end">
				<v-btn
					:disabled="loadingMyReports || (myReports && myReports.length === 0)"
					color="textPrimary"
					class="mr-5"
					outlined
					@click="getReports()"
				>
					<v-icon :class="hasAuthorityUploadMyReports ? 'mr-2' : ''">
						mdi-refresh
					</v-icon>
					Atualizar
				</v-btn>
			</v-col>
			<v-col cols="12" class="py-0">
				<v-data-table
					id="myReportsDataTable"
					class="elevation-1"
					item-key="id"
					:headers="headers"
					:items="myReports"
					:loading="loadingMyReports"
					:items-per-page="itemsPerPage"
					:server-items-length="totalPages"
					:page="page"
					:custon-sort="{
						isDescending: true
					}"
					:footer-props="{
						itemsPerPageOptions: [25, 50, 75, 100],
						disablePagination: loadingMyReports
					}"
					:height="myReports && myReports.length >= 10 ? 528 : null"
					@update:options="changePage($event)"
				>
					<template v-slot:no-data>
						<span>Nenhum dado encontrado.</span>
					</template>
					<template v-slot:[`item.reportLayout`]="{ item }">
						{{ getReportLayoutType(item.reportLayout) }}
					</template>
					<template v-slot:[`item.reportName`]="{ item }">
						{{ item.reportName || "-" }}
					</template>
					<template v-slot:[`item.updatedAt`]="{ item }">
						<span style="white-space: nowrap">
							{{
								item.updatedAt
									? formatter.formatDateTimeZoneWithHours(item.updatedAt)
									: "-"
							}}
						</span>
					</template>
					<template v-slot:[`item.createdAt`]="{ item }">
						<span style="white-space: nowrap">
							{{
								item.createdAt
									? formatter.formatDateTimeZoneWithHours(item.createdAt)
									: "-"
							}}
						</span>
					</template>
					<template v-slot:[`item.financialGroupId`]="{ item }">
						{{ item.financialGroupId ? (getFinancialGroup(item.financialGroupId).name) : "-" }}
					</template>
					<template v-slot:[`item.requestUserName`]="{ item }">
						{{ item.requestUserName ? formatter.formatToTitleCase(item.requestUserName) : "-" }}
					</template>
					<template v-slot:[`item.status`]="{ item }">
						<span class="text--truncate">{{
							getReportsStatus(item.status)
						}}</span>
					</template>
					<template v-slot:[`item.errors`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<v-btn
										color="primary"
										icon
										@click="openMyReportsErrorModal(item.id)"
										:disabled="!item.hasError">
										<v-icon
											:color="item.hasError ? 'error' : ''"
											size="25">
											fas fa-times
										</v-icon>
									</v-btn>
								</div>
							</template>
							<span> Erro no relatório </span>
						</v-tooltip>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-menu transition="slide-y-transition" offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									:disabled="item.status != 'SUCCESS'"
									icon
									v-bind="attrs"
									v-on="on"
									:loading="loadingReports[item.id]"
									@click="downloadReport(item.id, item.reportName)"
								>
									<v-icon
										color="textPrimary">
										mdi-tray-arrow-down
									</v-icon>
								</v-btn>
							</template>
						</v-menu>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<SnackbarCustomize ref="SnackbarCustomize" />

		<MyReportsErrorModal
			ref="MyReportsErrorModal"
			:myReportsError="propsError"
			:loadingTableMyReportsError="loadingErrorsModal"
			@cleanErrors="propsError = []"
		/>
	</v-container>
</template>

<script>

import MyReportsErrorModal from '@/components/DashboardMovementRecords/MyReports/MyReportsErrorModal.vue';
import AuthorityManagementMixin from "@/shared/mixins/authorityManagement/authorityManagementMixin";
import ReportAutomationMixin from '@/shared/mixins/reportAutomation/reportAutomationMixin';
import MovementRecordsMixin from "@/shared/mixins/movementRecords/movementRecordsMixin";
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import MyReportsService from "@/services-http/sdi/MyReportsService.js";
import MyReportsMixin from "@/shared/mixins/myReports/myReportsMixin";
import FileDataService from '@/services-http/sdi/FileDataService';
import Formatters from "@/shared/formatters/formatters";
import { cloneDeep } from "lodash";

export default {
	name: "MyReportsTable",

	components: {
		SnackbarCustomize,
		MyReportsErrorModal
	},

	data: () => ({
		loadingReports: {},

		loadingMyReports: false,
		hasAuthorityUploadMyReports: false,
		loadingErrorsModal: false,

		reportServices: null,
		fileDataService: null,

		page: 1,
		itemsPerPage: 25,
		totalPages: 0,

		propsError: [],


		myReports: [],
		headers: [
			{
				text: "Layout de Relatório",
				value: "reportLayout",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Nome do arquivo",
				value: "reportName",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Grupo Financeiro",
				value: "financialGroupId",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Solicitado por",
				value: "requestUserName",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Data da Solicitação",
				value: "createdAt",
				align: "start",
				class: "text--truncate ",
			},
			{
				text: "Data da Emissão",
				value: "updatedAt",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Status",
				value: "status",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Erro",
				value: "errors",
				align: "start",
				class: "text--truncate",
				sortable: false
			},
			{
				text: "Ações",
				value: "actions",
				align: "start",
				class: "text--truncate",
				sortable: false
			}
		]
	}),

	mixins: [
		AuthorityManagementMixin,
		MyReportsMixin,
		MovementRecordsMixin,
		ReportAutomationMixin
	],

	props: {
		financialGroups: { type: Array },
		resetFilterMyReports: { type: Boolean },
		isRHProtegido: {
			type: Boolean,
			default: false
		}
	},

	created() {
		this.formatter = new Formatters()
	},

	async mounted() {
		this.hasAuthorityUploadMyReports = true
		this.fileDataService = new FileDataService();
		this.reportServices = new MyReportsService();
	},

	methods: {
		// Update filters and restart report search
		async handlerFilter(value, type) {
			this.page = 1
			this.filterMyReports = cloneDeep(value)
			await this.getReports()
		},

		// Change table page
		async changePage(event) {
			this.itemsPerPage = event.itemsPerPage;
      if (this.page !== event.page) {
        this.handlePageChange(event);
      }
    },

		// Set the paging data
		async handlePageChange(event) {
      const { page: newPage, itemsPerPage } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      await this.getReports();
    },

		// Download the selected report
		async downloadReport(id, filename) {
			this.$set(this.loadingReports, id, true);

			await this.fileDataService.DownloadReport(id).then((response) => {
        if (response) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${filename}`);
          document.body.appendChild(fileLink);

          fileLink.click();
					this.$set(this.loadingReports, id, false);
          this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch(() => {
				this.$set(this.loadingReports, id, false);
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o download.');
      });
		},

		// Search reports with current filters
		async getReports() {
			this.loadingMyReports = true

			try {
				const filteredParams = this.getFilteredParams();
				if ('status' in filteredParams && filteredParams.status.length > 0 && filteredParams.status.includes("PARTIAL_FINISH")) {
					filteredParams.isPartialFinished = true;
				}
				const params = this.buildSearchParams(filteredParams, "search");
				await this.reportServices.GetReports(params).then(async (response) => {
					if (response.data) {
						this.myReports = response.data.content;
						this.totalPages = response.data.totalElements;
					}
				})
			} catch (error) {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os relatórios.');
			}

			this.loadingMyReports = false
		},

		// Assembles and returns the parameters of the this.filterMyReports object
		getFilteredParams() {
			return Object.entries(this.filterMyReports)
				.filter(([key, value]) => value && value !== "")
				.reduce((element, [key, value]) => ({ ...element, [key]: value }), {})
		},

		// Assembles and returns the parameters that will be sent in the request
		buildSearchParams(filteredParams, type) {
			const formattedFilterParams = cloneDeep(filteredParams)

			this.deleteEmptyParams(formattedFilterParams)

			return new URLSearchParams({
				...formattedFilterParams,
				isRHProtegido: this.isRHProtegido,
				page: this.page - 1,
				size: this.itemsPerPage,
				sort: "id,desc"
			})
		},

		// Remove parameters with value equal to '', null or undefined
		deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},

		// Presents screen errors through the dialog component
		async openMyReportsErrorModal(reportId) {
			await this.reportServices.GetReportError(reportId).then((res) => {
				if (res && res.data && res.data.length > 0) {
					this.propsError = res.data;
				}
			}).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os relatórios.');
			});
      this.$refs.MyReportsErrorModal.openModal();
		},

		// searches the financial group presented in the data-table
		getFinancialGroup(financialGroupId) {
			return this.financialGroups.find(el => el.id === financialGroupId) || '-'
		}
	},
}
</script>
<style>
#myReportsDataTable .v-data-table-header > tr > th > span {
	text-transform: none !important;
	font-weight: 600;
}
</style>
