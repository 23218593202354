<template>
  <v-container fluid class="px-5">
    <ViewTitles class="mx-2">
      <h2 class="primary--text" slot="title">Painel de Controle das Movimentações Cadastrais</h2>
    </ViewTitles>

    <v-row align="center" class="mx-1">
      <v-col>
        <v-tabs v-model="tab" class="mb-4" color="primary" background-color="var(--v-background-base)">
          <v-tab v-if="hasAuthorityProtocolViewTab" key='fileMovements' href='#fileMovements' style="font-size: 18px" @click="changeTab()">Movimentações via arquivo</v-tab>
          <v-tab v-if="hasAuthorityMovementViewTab" key='movements' href='#movements' style="font-size: 18px" @click="changeTab()">Movimentações</v-tab>
          <v-tab v-if="hasAuthorityBatchViewTab" key='shippingBatches' href='#shippingBatches' style="font-size: 18px" @click="changeTab()">Lote de envio</v-tab>
          <v-tab v-if="hasMyReportViewTab" key='myReports' href='#myReports' style="font-size: 18px" @click="changeTab()">Meus Relatórios</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="3" lg="2" class="pr-2">
        <CreateMovements />
      </v-col>
    </v-row>

    <v-row align="center" class="mx-1">
      <v-col class="px-2 pt-0">
        <v-expansion-panels v-model="isPanelExpanded">
          <v-expansion-panel>
            <v-expansion-panel-header style="cursor: auto;">
              <v-row @click.stop align="center">
                <v-col>
                  <v-icon color="primary" small class="mr-2">fas fa-filter</v-icon>
                  <span class="primary--text">Expandir Opções de Pesquisa</span>
                </v-col>
                <v-col sm="12" md="8" class="py-2 mr-5" align="end">
                  <DatePickerCustom
                    ref="DatePickerCustom"
                    :tab="tab"
                    :filtersForCheckDate="filtersForCheckDate"
                    @handlerClickFilter="handlerClickFilter"
                    @clearFilters="clearFilters"
                    @openExpandedContent="openExpandedContent"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FileMovementsContentFilter
                v-show="tab === 'fileMovements'"
                ref="FileMovementsContentFilter"
                :financialGroups="financialGroups"
                :resetFilterFileMovements="resetFilterFileMovements"
                @hasFiltersFileMovements="hasFiltersFileMovements"
                @resetStatusFilters="resetStatusFilters"
              />
              <MovementsContentFilter
                v-show="tab === 'movements'"
                ref="MovementsContentFilter"
                :insuranceCarriers="insuranceCarriers"
                :financialGroups="financialGroups"
                :isRHProtegido="isRHProtegido"
                :updateFilterField="updateFilterField"
                @hasFiltersMovements="hasFiltersMovements"
                @resetStatusFilters="resetStatusFilters"
                @finishinLoading="setLoadingPendingMovementsButton"
              />
              <ShippingBatchesContentFilter
                v-show="tab === 'shippingBatches'"
                ref="ShippingBatchesContentFilter"
                :financialGroups="financialGroups"
                :insuranceCarriers="insuranceCarriers"
                :resetFilterShippingBatches="resetFilterShippingBatches"
                @hasFiltersShippingBatches="hasFiltersShippingBatches"
                @resetStatusFilters="resetStatusFilters"
              />
              <MyReportsContentFilter
                v-show="tab === 'myReports'"
                ref="MyReportsContentFilter"
                :isRHProtegido="isRHProtegido"
                :financialGroups="financialGroups"
                :resetFilterMyReports="resetFilterMyReports"
                @hasFiltersMyReports="hasFiltersMyReports"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="px-6">
        <v-tabs-items class="transparent" style="border-radius: 5px;" v-model="tab" grow>
          <v-tab-item key='fileMovements' value='fileMovements'>
            <v-row>
              <FileMovementsTable
                :filterFileMovements="filterFileMovements"
                :financialGroups="financialGroups"
                :resetFilterFileMovements="resetFilterFileMovements"
                @updateFilterFileMovement="updateFilterFileMovement"
                @getMovementByProtocolId="getMovementByProtocolId"
              />
            </v-row>
          </v-tab-item>

          <v-tab-item key='movements' value='movements'>
            <v-row>
              <MovementsTable
                ref="MovementsTable"
                :financialGroups="financialGroups"
                :contracts="contracts"
                :subContracts="subContracts"
                @goToMyReportsTable="goToMyReportsTable"
              />
            </v-row>
          </v-tab-item>

          <v-tab-item key='shippingBatches' value='shippingBatches'>
            <v-row>
              <ShippingBatchesTable
                :filterShippingBatches="filterShippingBatches"
                :insuranceCarriers="insuranceCarriers"
                :resetFilterShippingBatches="resetFilterShippingBatches"
                :financialGroups="financialGroups"
                @getMovementByMovementInsuranceCarrierId="getMovementByMovementInsuranceCarrierId"
              />
            </v-row>
          </v-tab-item>

          <v-tab-item key='myReports' value='myReports'>
            <v-row>
              <MyReportsTable
                ref="MyReportsTable"
                :isRHProtegido="isRHProtegido"
                :financialGroups="financialGroups"
                :resetFilterMyReports="resetFilterMyReports"
              />
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-footer class="my-5">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickBack()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <ButtonPendingMovements
      v-if="tab === 'movements'"
      ref="ButtonPendingMovements"
      :isRHProtegido="isRHProtegido"
      @isPendingMovements="isPendingMovements"
    />
  </v-container>
</template>

<script>
import ViewTitles from '@/components/PageTitles/ViewTitles.vue';
import DatePickerCustom from '@/components/DashboardMovementRecords/Common/DatePickerCustom.vue';
import FileMovementsTable from '@/components/DashboardMovementRecords/FileMovements/FileMovementsTable.vue';
import FileMovementsContentFilter from '@/components/DashboardMovementRecords/FileMovements/FileMovementsContentFilter.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ShippingBatchesTable from '@/components/DashboardMovementRecords/ShippingBatches/ShippingBatchesTable.vue';
import ShippingBatchesContentFilter from '@/components/DashboardMovementRecords/ShippingBatches/ShippingBatchesContentFilter.vue';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import MovementsTable from '@/components/DashboardMovementRecords/Movements/MovementsTable.vue';
import MovementsContentFilter from '@/components/DashboardMovementRecords/Movements/MovementsContentFilter.vue';
import AuthorityManagement from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import MovementRecordsMixin from '@/shared/mixins/movementRecords/movementRecordsMixin';
import CreateMovements from '@/components/DashboardMovementRecords/Common/CreateMovements.vue';
import MyReportsContentFilter from '@/components/DashboardMovementRecords/MyReports/MyReportsContentFilter.vue';
import MyReportsTable from '@/components/DashboardMovementRecords/MyReports/MyReportsTable.vue';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Functions from '@/shared/functions/functions';
import ButtonPendingMovements from '@/components/CustomAlerts/ButtonPendingMovements.vue';

export default ({
  name: 'MovementRecord',

  components: {
    DatePickerCustom,
    ViewTitles,
    FileMovementsTable,
    FileMovementsContentFilter,
    ShippingBatchesTable,
    ShippingBatchesContentFilter,
    MovementsTable,
    MovementsContentFilter,
    CreateMovements,
    ButtonPendingMovements,
    MyReportsContentFilter,
    MyReportsTable
  },

  data: () => ({
    tab: 'fileMovements',
    hasAuthorityBatchViewTab: false,
    hasAuthorityProtocolViewTab: false,
    hasAuthorityMovementViewTab: false,
    hasMyReportViewTab: false,
    isRHProtegido: false,
    resetDate: false,
    isPanelExpanded: false,
    resetFilterFileMovements: false,
    resetFilterShippingBatches: false,
    resetFilterMyReports: false,
    updateFilterField: {},

    dataFilter: {},
    dataFilterFileMovements: {},
    dataFilterShippingBatches: {},
    dataFilterMovements: {},
    dataFilterMyReports: {},
    filtersForCheckDate: {},
    filterForCheckFileMovement: {},
    filterForCheckMovement: {},
    filterForCheckShippingBatches: {},
    filterForCheckMyReports: {},
    dateType: [],

    dates: [],
    financialGroups: [],
    insuranceCarriers: [],
    contracts: [],
    subContracts: [],
  }),

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
    AuthorityManagement,
    MovementRecordsMixin,
  ],

  created() {
    this.functions = new Functions();
  },

  mounted() {
    this.managementAuthorities();
    this.getFinancialGroups();
    this.getInsuranceCarriers();
    this.verifySessionStorageAndRouteParams();

    if (this.tab === 'fileMovements') {
      this.changeTab();
    }
  },

  watch: {
    tab: {
      handler(newTab, oldTab) {
        switch (oldTab) {
          case 'fileMovements':
            this.filterForCheckFileMovement = this.filtersForCheckDate;
            break;
          case 'shippingBatches':
            this.filterForCheckShippingBatches = this.filtersForCheckDate;
            break;
          case 'movements':
            this.filterForCheckMovement = this.filtersForCheckDate;
            break;
          case 'myReports':
            this.filterForCheckMyReports = this.filtersForCheckDate;
            break;
        }

        switch (newTab) {
          case 'fileMovements':
            this.filtersForCheckDate = this.filterForCheckFileMovement;
            break;
          case 'shippingBatches':
            this.filtersForCheckDate = this.filterForCheckShippingBatches;
            break;
          case 'movements':
            this.filtersForCheckDate = this.filterForCheckMovement;
            break;
          case 'myReports':
            this.filtersForCheckDate = this.filterForCheckMyReports;
            break;
        }
      },
      deep: true
    }
  },

  methods: {
    verifySessionStorageAndRouteParams() {
      if (sessionStorage.getItem('searchParams')) {
        const session = JSON.parse(sessionStorage.getItem('searchParams'));
        this.tab = session.tab;
      } else if (this.$route.params.movement) {
        this.tab = 'movements';
        this.changeTab();
      }
    },
    managementAuthorities() {
      this.hasAuthorityBatchViewTab = !!(this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_lote') && !this.isRHProtegido);
      this.hasAuthorityProtocolViewTab = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_protocolo');
      this.hasAuthorityMovementViewTab = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_movimentacao');
      this.hasMyReportViewTab = this.hasPermission('sdi_painel_movimentacoes_cadastrais_visualizar_movimentacao');
    },
    handlerClickFilter(dates, dateType) {
      if (dates && dates.length > 0) {
        this.processDates(dates, dateType);
      } else {
        this.dates = [];
      }

      const commonFilterProperties = this.setCommonFilterProperties();

      this.handleTabFiltering(commonFilterProperties);
    },
    processDates(dates, dateType) {
      const startDate = moment(dates[0]);
      const endDate = moment(dates[1]);
      this.dateType = dateType;

      this.dates[0] = this.setDateTimezone(startDate.startOf('day'))
      this.dates[1] = this.setDateTimezone(endDate.endOf('day').add(3, 'hours'))

      this.dates[0] = this.formatDate(this.dates[0])
      this.dates[1] = this.formatDate(this.dates[1])
    },
    setDateTimezone(date) {
      return date.add(3, 'hours')
    },
    formatDate(date) {
      return date.format('YYYY-MM-DDTHH:mm:ss.SSS');
    },
    setCommonFilterProperties() {
      return {
        startDate: this.dates[0],
        endDate: this.dates[1],
        dateType: this.dateType,
      };
    },
    updateFilterFileMovement(key, value) {
      this.filterFileMovements[key] = value;
    },
    handleTabFiltering(commonFilterProperties) {
      switch (this.tab) {
        case 'fileMovements':
          this.handleFileMovements();
          break;
        case 'shippingBatches':
          this.handleShippingBatches(commonFilterProperties);
          break;
        case 'movements':
          this.handleMovements(commonFilterProperties);
          break;
        case 'myReports':
          this.handleMyReports(commonFilterProperties);
          break;
        default:
          console.error(`Unsupported tab: ${this.tab}`);
          break;
      }
    },
    handleFileMovements() {
      this.resetFilterFileMovements = false;
      this.filterFileMovements = {
        ...this.dataFilterFileMovements,
        receivingDateStart: this.dates[0],
        receivingDateEnd: this.dates[1],
      };
    },
    handleShippingBatches(commonFilterProperties) {
      this.resetFilterShippingBatches = false;
      this.filterShippingBatches = {
        ...this.dataFilterShippingBatches,
        ...commonFilterProperties,
      };
    },
    handleMovements(commonFilterProperties) {
      let filters = { ...this.dataFilterMovements };

      const hasPartialSuccessStatus =
        Array.isArray(filters.status) && filters.status.some(
          (movementStatus) => movementStatus === 'PARTIAL_FINISH');

      if (hasPartialSuccessStatus) {
        const statusFilter = {
          isPartialFinished: true,
        };

        filters = {
          ...filters,
          ...statusFilter,
        };
      }

      const filterMovements = {
        ...filters,
        ...commonFilterProperties,
      };

      this.$refs.MovementsTable.handlerFilter(filterMovements, 'click');
    },
    handleMyReports(commonFilterProperties) {
      this.resetFilterMyReports = false;

      this.filterMyReports = {
        ...this.dataFilterMyReports,
        receivingDateStart: this.dates[0],
        receivingDateEnd: this.dates[1],
      }

      setTimeout(() => {
        this.$refs.MyReportsTable.handlerFilter(this.filterMyReports, 'click');
      }, 200);
    },
    hasFiltersFileMovements(data, type) {
      this.dataFilterFileMovements = cloneDeep(data);
      this.filtersForCheckDate = cloneDeep(data);
      if (type === 'getFilterByFavorite') {
        this.$refs.DatePickerCustom.getFilterByFavorite(data);
      }
    },
    hasFiltersShippingBatches(data, type) {
      this.dataFilterShippingBatches = cloneDeep(data);
      if (type === 'getFilterByFavorite') {
        this.$refs.DatePickerCustom.getFilterByFavorite(data);
      }
    },
    hasFiltersMovements(data, type) {
      this.dataFilterMovements = cloneDeep(data.filterMovements);
      this.filtersForCheckDate = cloneDeep(data.filterMovements);
      this.contracts = data.contracts;
      this.subContracts = data.subContracts;
      if (type === 'getFilterByFavorite') {
        this.$refs.DatePickerCustom.getFilterByFavorite(data.filterMovements);
      }
    },
    hasFiltersMyReports(data, type) {
      this.dataFilterMyReports = cloneDeep(data);
    },
    async getMovementByProtocolId(id, status) {
      await this.setMovementFilter('protocol', id, status);
    },
    async getMovementByMovementInsuranceCarrierId(id, status) {
      await this.setMovementFilter('batch', id, status);
    },
    async setMovementFilter(type, id, status) {
      const idNumber = Number(id);
      this.tab = 'movements';
      this.isPanelExpanded = 0;

      sessionStorage.setItem('hasFilter', true);
      this.filterMovements.protocolNumber = type === 'protocol' ? idNumber : '';
      this.filterMovements.movementInsuranceCarrierId = type === 'batch' ? idNumber : '';

      if (status) {
        this.filterMovements.status = [status];
      }
      this.updateFilterField = {
        movementInsuranceCarrierId: this.filterMovements.movementInsuranceCarrierId,
        protocolNumber: this.filterMovements.protocolNumber
      }

      setTimeout(() => {
        this.$refs.MovementsTable.handlerFilter(this.filterMovements);
      }, 200);
    },
    resetGetFilterMovement() {
      this.$refs.MovementsTable.getFilterMovement();
    },
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
    clearFilters() {
      if (this.tab === 'fileMovements') {
        this.resetFilterFileMovements = true;
      }
      if (this.tab === 'shippingBatches') {
        this.resetFilterShippingBatches = true;
      }
      if (this.tab === 'movements') {
        setTimeout(() => {
          this.$refs.MovementsContentFilter.clearFilterMovements();
        })
      }
      if (this.tab === 'myReports') {
        this.resetFilterMyReports = true;
      }
    },
    resetStatusFilters(data) {
      if (this.tab === 'fileMovements') {
        this.resetFilterFileMovements = data;
      }
      if (this.tab === 'shippingBatches') {
        this.resetFilterShippingBatches = data;
      }
      if (this.tab === 'myReports') {
        this.resetFilterMyReports = data;
      }
    },
    openExpandedContent(value) {
      this.isPanelExpanded = value;
    },
    changeTab() {
      const keysToPreserve = ['@auth/token', '@auth/user', 'selectedDate', 'movementRecordIds','@sdi/app_origin'];
      this.functions.clearSessionStorage(keysToPreserve);

      this.updateFilterField = {}

      this.requestDataTable(100);
    },
    requestDataTable(time) {
      setTimeout(() => {
        this.$refs.DatePickerCustom.handlerFilter();
      }, time);
    },
    async isPendingMovements() {
      this.openExpandedContent(0);

      const datePickerTimeout = 200;
      const movementsTableTimeout = 100;

      await new Promise((resolve) => setTimeout(resolve, datePickerTimeout));
      this.$refs.DatePickerCustom.setNoDate();

      await new Promise((resolve) => setTimeout(resolve, movementsTableTimeout));
      this.$refs.MovementsContentFilter.verifySessionStorage('isPendingMovements');
    },
    setLoadingPendingMovementsButton(value, filterMovements) {
      filterMovements.pendingMovements = true;
      this.$refs.ButtonPendingMovements.setLoadingButton(value);
      this.$refs.MovementsTable.handlerFilter(filterMovements, 'isPendingMovements');
    },
    goToMyReportsTable() {
      this.tab = 'myReports';
      const commonFilterProperties = this.setCommonFilterProperties();
      this.handleTabFiltering(commonFilterProperties);
    },
  },
});
</script>

<style>
.v-expansion-panel-header__icon {
  cursor: pointer;
}
.v-expansion-panel-header:before {
  background-color: #f4f4f4;
}
</style>
