<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="mb-4" color="primary">
      <v-tab key="searchFilters" href="#searchFilters" style="font-size: 16px" aria-label="Nova Pesquisa">
        <v-icon dense class="mr-2">fas fa-search</v-icon>
        Nova Pesquisa
      </v-tab>
      <v-tab key="favoriteFilters" href="#favoriteFilters" style="font-size: 16px" aria-label="Pesquisas Salvas">
        <v-icon dense class="mr-2">far fa-bookmark</v-icon>
        Pesquisas Salvas
      </v-tab>
    </v-tabs>

    <v-tabs-items style="border-radius: 5px;" v-model="tab" grow>
      <v-tab-item key='searchFilters' value='searchFilters'>
        <v-row v-show="!overlay" class="mt-2 text--primary">
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Somente protocolos manuais</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="onlyManualProtocols"
              v-model="filterMovements.onlyManualProtocol"
              @input="onlyManualProtocolControl"
              :search-input.sync="onlyManualProtocolSearch"
              @change="onlyManualProtocolSearch = null"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Protocolo Arquivo</label>
            <v-text-field
              class="mt-1"
              v-model="filterMovements.protocolNumber"
              v-mask="'##########'"
              type="number"
              min="1"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @blur="validateFieldNumber('protocolNumber')"
              validate-on-blur
              color="textPrimary"
              dense
              outlined
              :disabled="filterMovements.onlyManualProtocol ? true : false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Protocolo Movimentação</label>
            <v-text-field
              class="mt-1"
              v-model="filterMovements.movementRecordId"
              v-mask="'##########'"
              type="number"
              min="1"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @blur="validateFieldNumber('movementRecordId')"
              color="textPrimary"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Protocolo Lote Envio</label>
            <v-text-field
              class="mt-1"
              v-model="filterMovements.movementInsuranceCarrierId"
              v-mask="'##########'"
              type="number"
              min="1"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @blur="validateFieldNumber('movementInsuranceCarrierId')"
              color="textPrimary"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Grupo Financeiro</label>
            <v-autocomplete
              v-model="filterMovements.financialGroup"
              class="mt-1"
              placeholder="Selecione"
              item-text="name"
              item-value="id"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="financialGroups"
              :search-input.sync="financialGroupSearch"
              @change="financialGroupSearch = null;"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Contrato</label>
            <v-autocomplete
              class="mt-1"
              v-model="filterMovements.contract"
              :items="contracts"
              :loading="loadingContracts"
              item-text="text"
              item-value="id"
              dense
              outlined
              color="textPrimary"
              item-color="textPrimary"
              placeholder="Selecione"
              :search-input.sync="contractSearch"
              @change="contractSearch = null"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Sub-Contrato</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              v-model="filterMovements.subContract"
              :items="subContracts"
              :loading="loadingSubContracts"
              item-text="text"
              item-value="id"
              dense
              outlined
              color="textPrimary"
              item-color="textPrimary"
              :search-input.sync="subContractSearch"
              @change="subContractSearch = null"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Operadora</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              v-model="filterMovements.insuranceCarrierIds"
              :items="insuranceCarriers"
              item-text="xipp_commercial_name"
              item-value="id"
              outlined
              dense
              multiple
              :rules="filterMovements.insuranceCarrierIds && filterMovements.insuranceCarrierIds.length > 0 ? [rule.handleSelectionLimit(filterMovements.insuranceCarrierIds, 5)] : []"
              chips
              small-chips
              clearable
              :search-input.sync="carrierSearch"
              @change="carrierSearch = null"
            >
              <template v-slot:selection="data">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.xipp_commercial_name }} </v-chip>
                  </template>
                  <span>{{ data.item.xipp_commercial_name }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Tipo de Movimentação</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              :items="movementTypes"
              v-model="filterMovements.movementType"
              :rules="filterMovements.movementType && filterMovements.movementType.length > 0 ? [rule.handleSelectionLimit(filterMovements.movementType, 5)] : []"
              item-text="description"
              item-value="code"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              deletable-chips
              chips
              small-chips
              clearable
              multiple
              :search-input.sync="movementTypesSearch"
              @change="movementTypesSearch = null"
            >
            <template v-slot:selection="data">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.description }} </v-chip>
                </template>
                <span>{{ data.item.description }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Status da Movimentação</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              :items="movementStatus"
              v-model="filterMovements.status"
              :rules="filterMovements.status && filterMovements.status.length > 0 ? [rule.handleSelectionLimit(filterMovements.status, 5)] : []"
              item-text="name"
              item-value="value"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              deletable-chips
              chips
              small-chips
              clearable
              multiple
              :search-input.sync="movementStatusSearch"
              @change="movementStatusSearch = null"
            >
            <template v-slot:selection="data">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.name }} </v-chip>
                </template>
                <span>{{ data.item.name }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>CPF do Beneficiário</label>
            <v-text-field
              class="mt-1"
              v-model="filterMovements.document"
              :rules="filterMovements.document ? [rule.cpf] : []"
              color="textPrimary"
              v-mask="'###.###.###-##'"
              type="tel"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Nome do Beneficiário</label>
            <v-text-field
              class="mt-1"
              v-model.trim="filterMovements.name"
              :rules="filterMovements.name ? [rule.required, rule.name, rule.validateIsAlphanumeric] : []"
              @blur="filterMovements.name ? filterMovements.name = formatter.formatToTitleCase(filterMovements.name) : null"
              maxlength="70"
              color="textPrimary"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>CPF do Dependente</label>
            <v-text-field
              class="mt-1"
              v-model="filterMovements.documentDependent"
              :rules="filterMovements.documentDependent ? [rule.cpf] : []"
              color="textPrimary"
              v-mask="'###.###.###-##'"
              type="tel"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Nome do Dependente</label>
            <v-text-field
              class="mt-1"
              v-model.trim="filterMovements.nameDependent"
              :rules="filterMovements.nameDependent ? [rule.required, rule.name, rule.validateIsAlphanumeric] : []"
              @blur="filterMovements.nameDependent ? filterMovements.nameDependent = formatter.formatToTitleCase(filterMovements.nameDependent) : null"
              maxlength="70"
              color="textPrimary"
              dense
              outlined
            />
          </v-col>
          <v-col v-if="!isRHProtegido" cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Canais de Entrada</label>
            <v-autocomplete
              :items="entryChannels"
              v-model="filterMovements.appOrigin"
              class="mt-1"
              placeholder="Selecione"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :search-input.sync="entryChannelsSearch"
              @change="entryChannelsSearch = null"
            />
          </v-col>
          <v-col v-if="!isRHProtegido" cols="12" sm="6" md="4" lg="3" xl="2" class="py-0">
            <label>Crítica interna Pendente?</label>
            <v-autocomplete
              :items="internalCriticismOptions"
              v-model="filterMovements.internalCriticism"
              class="mt-1"
              placeholder="Selecione"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :search-input.sync="internalCriticismSearch"
              @change="internalCriticismSearch = null"
            />
          </v-col>
        </v-row>

        <v-row v-show="overlay" class="primary--text my-15">
          <v-col cols="12" align="center">
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
              width="8"
            />
          </v-col>
          <v-col cols=12 align="center">
            Carregando Filtros
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item key='favoriteFilters' value='favoriteFilters'>
        <FavoriteSearchComponent
          filterBy="MovementsContentFilter"
          :searchFilter="filterMovements"
          :changeTabValue="changeTabValue"
          @loadThisFilter="loadThisFilter"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MovementRecordsMixin from '@/shared/mixins/movementRecords/movementRecordsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import FavoriteSearchComponent from '@/components/FavoriteSearchFilters/FavoriteSearchComponent.vue';
import lodash from 'lodash';

export default ({
  name: 'MovementsContentFilter',

  components: { FavoriteSearchComponent },

  data: () => ({
    carrierSearch: null,
    contractSearch: null,
    subContractSearch: null,
    movementTypesSearch: null,
    entryChannelsSearch: null,
    financialGroupSearch: null,
    movementStatusSearch: null,
    internalCriticismSearch: null,
    onlyManualProtocolSearch: null,
    loadingContracts: false,
    loadingSubContracts: false,
    isUpdatingSessionStorage: false,
    overlay: false,
    watcherCalledGetContract: false,
    watcherCalledGetSubContract: false,
    tab: 'searchFilters',
    changeTabValue: 'MOVEMENT',
    contracts: [],
    subContracts: [],
    onlyManualProtocols: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
    movementStatus: [
      {
        name: 'Aguardando liberação',
        value: 'WAITING_APPROVAL_RULES_VALIDATIONS',
      },
      {
        name: 'Criticado Cadastro',
        value: 'INVALID',
      },
      {
        name: 'Criticado Operadora',
        value: 'INVALID_IN_CARRIER',
      },
      {
        name: 'Finalizado',
        value: 'SUCCESS',
      },
      {
        name: 'Finalizado Parcial',
        value: 'PARTIAL_FINISH',
      },
      {
        name: 'Em processamento',
        value: 'TRANSFORMED, WAIT_RULES_VALIDATION, WAIT_MOVEMENT_TYPE_CLASSIFICATION',
      },
      {
        name: 'Enviado para operadora',
        value: 'SUBMITTED',
      },
      {
        name: 'Liberada',
        value: 'VALID, ELIGIBLE, ADJUSTED',
      },
      {
        name: 'Cancelado',
        value: 'CANCELED',
      },
      {
        name: 'Agendado',
        value: 'SCHEDULED',
      },
      {
        name: 'Em análise',
        value: 'IN_REVIEW',
      },
      {
        name: 'Separado para envio',
        value: 'WAIT_SUBMISSION',
      },
      {
        name: 'Aguardando atendimento',
        value: 'WAIT_SUPPORT',
      },
      {
        name: 'Finalizado Parcialmente',
        value: 'PARTIAL_FINISH',
      },
    ],
    entryChannels: [
      { text: 'Todos canais', value: 'allChannels' },
      { text: 'RH Protegido', value: 'PROTECTED_HR' },
      { text: 'Plataforma Xipp', value: 'BACKOFFICE' },
      { text: 'Folha Cliente', value: 'PAYROLL_API' },
      { text: 'Api Xipp', value: 'MOVEMENT_API' },
    ],
    internalCriticismOptions: [
      { text: 'Ambas', value: 'both' },
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
  }),

  mixins: [
    MovementRecordsMixin,
    ContractsMixin,
  ],

  props: {
    insuranceCarriers: {
      type: Array,
      default: () => [],
    },
    financialGroups: {
      type: Array,
      default: () => [],
    },
    dates: { type: Object },
    updateFilterField: {
      type: Object,
      default: () => {}
    },
    isRHProtegido: { type: Boolean },
  },

  watch: {
    filterMovements: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value && !this.isUpdatingSessionStorage) {
          this.isUpdatingSessionStorage = true;
          const data = {
            filterMovements: lodash.cloneDeep(this.filterMovements),
            contracts: lodash.cloneDeep(this.contracts),
            subContracts: lodash.cloneDeep(this.subContracts),
          };
          this.$emit('hasFiltersMovements', data);
          this.isUpdatingSessionStorage = false;
        }
      },
    },
    updateFilterField: {
      deep: true,
      immediate: true,
      handler(value) {
        Object.keys(this.updateFilterField).forEach((key) => {
          this.filterMovements[key] = value[key]
        })
      },
    },
    'filterMovements.financialGroup': {
      deep: true,
      handler(value) {
        const financialGroupId = lodash.cloneDeep(value);
        if (financialGroupId) {
          if (!this.watcherCalledGetContract) {
            this.loadContractsByFinancialGroupId(financialGroupId);
          }
        } else {
          this.filterMovements.contract = null;
          this.filterMovements.subContract = null;
          this.contracts = [];
          this.subContracts = [];
          this.watcherCalledGetContract = false;
        }
      },
    },
    'filterMovements.contract': {
      deep: true,
      handler(value) {
        if (value) {
          if (!this.watcherCalledGetSubContract) {
            this.loadSubContractsByContractId(value);
          }
        } else {
          this.filterMovements.subContract = null;
          this.subContracts = [];
          this.watcherCalledGetSubContract = false;
        }
      },
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
  },

  mounted() {
    this.verifySessionStorage();
    this.loadMovementTypes();
    this.reorderArrays();
  },

  methods: {
    reorderArrays() {
      this.movementStatus.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    onlyManualProtocolControl() {
      this.filterMovements.protocolNumber = this.filterMovements.onlyManualProtocol ? '' : this.filterMovements.protocolNumber;
      this.$emit('hasFiltersMovements', this.filterMovements);
    },
    validateFieldNumber(fieldName) {
      if (fieldName) {
        const fieldValue = this.filterMovements[fieldName];
        const sanitizedValue = fieldValue.replace(/[^0-9]/g, '');
        if (sanitizedValue === '' || sanitizedValue === 0 || typeof sanitizedValue === 'undefined') {
          this.filterMovements[fieldName] = '';
        } else {
          this.filterMovements[fieldName] = sanitizedValue.replace(/^0+/, '');
        }
      }
    },
    verifySessionStorage(type) {
      if (sessionStorage.getItem('searchParams')) {
        const sessionData = JSON.parse(sessionStorage.getItem('searchParams'));
        const newFilterMovements = { ...this.filterMovements };

        newFilterMovements.onlyManualProtocol = sessionData.onlyManualProtocol ? sessionData.onlyManualProtocol : false;
        newFilterMovements.protocolNumber = sessionData.protocolNumber ? sessionData.protocolNumber : '';
        newFilterMovements.movementRecordId = sessionData.movementRecordId ? sessionData.movementRecordId : '';
        newFilterMovements.financialGroup = sessionData.financialGroup ? sessionData.financialGroup : '';
        newFilterMovements.contract = sessionData.contract ? sessionData.contract : '';
        newFilterMovements.subContract = sessionData.subContract ? sessionData.subContract : '';
        newFilterMovements.insuranceCarrierIds = sessionData.insuranceCarrierIds ? sessionData.insuranceCarrierIds : [];
        newFilterMovements.movementType = sessionData.movementType ? sessionData.movementType : [];
        newFilterMovements.status = sessionData.status ? sessionData.status : [];
        newFilterMovements.document = sessionData.document ? sessionData.document : '';
        newFilterMovements.name = sessionData.name ? sessionData.name : '';
        newFilterMovements.internalCriticism = sessionData.internalCriticism ? sessionData.internalCriticism : 'both';
        this.filterMovements = newFilterMovements;

        if (type === 'isPendingMovements') {
          this.$emit('finishinLoading', false, this.filterMovements);
        }
      }
    },
    clearFilterMovements() {
      setTimeout(() => {
        this.filterMovements = {
          protocolNumber: '',
          movementRecordId: '',
          movementInsuranceCarrierId: '',
          fileDataId: '',
          financialGroup: '',
          contract: '',
          subContract: '',
          name: '',
          document: '',
          movementType: [],
          status: [],
          dateType: 'isMovementDate',
          receivingDateStart: '',
          receivingDateEnd: '',
          insuranceCarrierIds: [],
          onlyManualProtocol: false,
          onlyErrors: false,
          appOrigin: 'allChannels',
          internalCriticism: 'both',
        };
      }, 100);

      this.$emit('resetStatusFilters', false);
    },
    async loadThisFilter(data) {
      data.queryParams = this.transformJoinToArray(data.queryParams);
      this.transformStatus(data.queryParams);
      this.transformMovementType(data.queryParams);
      this.transformStringToNumber(data.queryParams);
      this.transformStringBoolean(data.queryParams);

      const dateType = this.getDateTypes(data.queryParams);

      this.filterMovements = {
        protocolNumber: data.queryParams.protocolNumber ? data.queryParams.protocolNumber : '',
        movementRecordId: data.queryParams.movementRecordId ? data.queryParams.movementRecordId : '',
        movementInsuranceCarrierId: data.queryParams.movementInsuranceCarrierId ? data.queryParams.movementInsuranceCarrierId : '',
        fileDataId: data.queryParams.fileDataId ? data.queryParams.fileDataId : '',
        financialGroup: data.queryParams.financialGroup ? Number(data.queryParams.financialGroup) : '',
        contract: data.queryParams.contract ? Number(data.queryParams.contract) : '',
        subContract: data.queryParams.subContract ? Number(data.queryParams.subContract) : '',
        name: data.queryParams.name ? data.queryParams.name : '',
        document: data.queryParams.document ? data.queryParams.document : '',
        movementType: data.queryParams.movementType ? data.queryParams.movementType : [],
        status: data.queryParams.status ? data.queryParams.status : [],
        dateType,
        receivingDateStart: data.queryParams.receivingDateStart ? data.queryParams.receivingDateStart : '',
        receivingDateEnd: data.queryParams.receivingDateEnd ? data.queryParams.receivingDateEnd : '',
        insuranceCarrierIds: data.queryParams.insuranceCarrierIds ? data.queryParams.insuranceCarrierIds : [],
        onlyManualProtocol: data.queryParams.onlyManualProtocol ? data.queryParams.onlyManualProtocol : false,
        onlyErrors: data.queryParams.onlyErrors ? data.queryParams.onlyErrors : false,
        appOrigin: data.queryParams.appOrigin ? data.queryParams.appOrigin : 'allChannels',
        selectedDate: data.queryParams.selectedDate,
        internalCriticism: data.queryParams.internalCriticism ? data.queryParams.internalCriticism : 'both',
      };

      this.tab = 'searchFilters';
      await this.verifyContractsAndSubContracts();
    },
    transformJoinToArray(queryParams) {
      const transformedParams = lodash.cloneDeep(queryParams);
      if (typeof transformedParams.insuranceCarrierIds === 'string' && transformedParams.insuranceCarrierIds.includes(',')) {
        transformedParams.insuranceCarrierIds = transformedParams.insuranceCarrierIds.split(',');
      }
      if (typeof transformedParams.movementType === 'string' && transformedParams.movementType.includes(',')) {
        transformedParams.movementType = transformedParams.movementType.split(',');
      }
      if (typeof transformedParams.status === 'string' && transformedParams.status.includes(',')) {
        transformedParams.status = transformedParams.status.split(',');
      }

      return transformedParams;
    },
    transformMovementType(queryParams) {
      if (Array.isArray(queryParams.movementType)) {
        if (queryParams.movementType.length > 0) {
          const movementMovementTypeSet = new Set();

          queryParams.movementType.forEach((movementType) => {
            switch (movementType) {
              case 'INSERT':
                movementMovementTypeSet.add('INSERT');
                break;
              case 'IMPLANTATION':
                movementMovementTypeSet.add('IMPLANTATION');
                break;
              case 'UPDATE':
                movementMovementTypeSet.add('UPDATE');
                break;
              case 'DELETE':
                movementMovementTypeSet.add('DELETE');
                break;
              default:
                movementMovementTypeSet.add(movementType);
                break;
            }
          });
          const movementStatus = Array.from(movementMovementTypeSet);

          queryParams.movementType = movementStatus;
        }
      } else if (typeof queryParams.movementType === 'string') {
        queryParams.movementType = [queryParams.movementType];
      }
    },
    transformStatus(queryParams) {
      if (Array.isArray(queryParams.status)) {
        if (queryParams.status.length > 0) {
          const movementStatusSet = new Set();

          queryParams.status.forEach((status) => {
            switch (status) {
              case 'WAITING_APPROVAL_RULES_VALIDATIONS':
                movementStatusSet.add('WAITING_APPROVAL_RULES_VALIDATIONS');
                break;
              case 'INVALID':
              case 'INVALID_IN_CARRIER':
                movementStatusSet.add('INVALID, INVALID_IN_CARRIER');
                break;
              case 'SUCCESS':
                movementStatusSet.add('SUCCESS');
                break;
              case 'TRANSFORMED':
              case 'WAIT_RULES_VALIDATION':
              case 'WAIT_MOVEMENT_TYPE_CLASSIFICATION':
                movementStatusSet.add('TRANSFORMED, WAIT_RULES_VALIDATION, WAIT_MOVEMENT_TYPE_CLASSIFICATION');
                break;
              case 'SUBMITTED':
                movementStatusSet.add('SUBMITTED');
                break;
              case 'VALID':
              case 'ELIGIBLE':
              case 'ADJUSTED':
                movementStatusSet.add('VALID, ELIGIBLE, ADJUSTED');
                break;
              case 'CANCELED':
                movementStatusSet.add('CANCELED');
                break;
              case 'SCHEDULED':
                movementStatusSet.add('SCHEDULED');
                break;
              default:
                movementStatusSet.add(status);
                break;
            }
          });

          const movementStatus = Array.from(movementStatusSet);

          queryParams.status = movementStatus;
        }
      } else if (typeof queryParams.status === 'string') {
        queryParams.status = [queryParams.status];
      }
    },
    transformStringToNumber(queryParams) {
      queryParams.insuranceCarrierIds = queryParams.insuranceCarrierIds ? this.transformStringArrayToNumbers(queryParams.insuranceCarrierIds) : [];
    },
    transformStringArrayToNumbers(stringArray) {
      if (Array.isArray(stringArray)) {
        return stringArray.map((item) => parseFloat(item));
      }
      return parseFloat(stringArray);
    },
    transformStringBoolean(queryParams) {
      Object.keys(queryParams).forEach((chave) => {
        if (queryParams[chave] === 'true') {
          queryParams[chave] = true;
        } else if (queryParams[chave] === 'false') {
          queryParams[chave] = false;
        }
      });
    },
    getDateTypes(queryParams) {
      const dateType = [];
      if (queryParams.isCarrierFinishedDate) dateType.push('isCarrierFinishedDate');
      if (queryParams.isCarrierSendDate) dateType.push('isCarrierSendDate');
      if (queryParams.isMovementDate) dateType.push('isMovementDate');
      if (queryParams.isValidityDate) dateType.push('isValidityDate');
      if (dateType.length === 0) dateType.push('isMovementDate');
      return dateType;
    },
    setOverlay(value) {
      this.overlay = value;
    },
    async verifyContractsAndSubContracts() {
      this.setOverlay(true);
      let data = {};

      if (this.filterMovements.financialGroup && this.filterMovements.contract) {
        this.watcherCalledGetContract = true;
        this.watcherCalledGetSubContract = true;
        await this.loadContractsByFinancialGroupId(this.filterMovements.financialGroup);
        await this.loadSubContractsByContractId(this.filterMovements.contract);
      }

      if (this.contracts && this.contracts.length > 0) {
        data = {
          contracts: lodash.cloneDeep(this.contracts),
          subContracts: lodash.cloneDeep(this.subContracts),
        };
      }

      data.filterMovements = lodash.cloneDeep(this.filterMovements);

      this.$emit('hasFiltersMovements', data, 'getFilterByFavorite');
      this.watcherCalledGetContract = false;
      this.watcherCalledGetSubContract = false;
      setTimeout(() => {
        this.setOverlay(false);
      }, 500);
    },
    loadContractsByFinancialGroupId(financialGroupId) {
      return new Promise((resolve) => {
        this.getContractsByFinancialGroupIdVerifyRhProtegido(financialGroupId, this.isRHProtegido).then(() => {
          this.isDisableContract = false;
          resolve();
        });
      });
    },
    async loadSubContractsByContractId(contractId) {
      return new Promise((resolve) => {
        this.getSubContractsByContractId(contractId).then(() => {
          resolve();
        });
      });
    },
  },
});
</script>
